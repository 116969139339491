





























































import { deleteRole, getRoleList } from '@/api/role';
import { Component, Vue } from 'vue-property-decorator';
import Pagination from '@/components/Pagination/index.vue';
import RoleForm from './components/RoleForm.vue';
import UserList from './components/UserList.vue';

@Component({
  name: 'RoleManage',
  components: {
    Pagination,
    RoleForm,
    UserList,
  },
})
export default class extends Vue {
  private formVisible = false;

  private roleList = [];

  private totalElements = 0;

  private roleCode = '';

  private loading = true;

  private listQuery: any = {
    page: 1,
    size: 10,
  }

  mounted() {
    this.getRoleList();
  }

  private handelRoleClick(data: any) {
    this.roleCode = data.roleCode;
    (this.$refs.userList as any).handleChangeRoleCode(this.roleCode);
  }

  private handleVisibleForm(roleCode: string) {
    this.roleCode = roleCode;
    this.formVisible = !this.formVisible;
  }

  private deleteRole(roleUid:string) {
    this.$confirm('권한과 관련된 데이터는 모두 삭제됩니다.<br />정말 삭제하시겠습니까?', '경고', {
      dangerouslyUseHTMLString: true,
      confirmButtonText: '삭제',
      cancelButtonText: '취소',
      type: 'warning',
    }).then(() => {
      deleteRole(roleUid).then(() => {
        this.getRoleList();
        this.$notify({
          title: '메세지',
          message: '정상적으로 삭제되었습니다.',
          type: 'success',
          duration: 2000,
        });
      });
    });
  }

  private getRoleList() {
    this.loading = true;
    getRoleList({
      ...this.listQuery,
      page: this.listQuery.page - 1,
    }).then((res) => {
      this.roleList = res.data.content;
      this.totalElements = res.data.totalElements;
      this.loading = false;
    });
  }

  private handleChangePage(pageable: any) {
    this.listQuery.page = pageable.page;
    this.getRoleList();
  }

  private getRowClass(data: any) {
    if (this.roleCode === data.row.roleCode) return 'active-row';
    return '';
  }
}
