























import { Component, Prop, Vue } from 'vue-property-decorator';
import { Form } from 'element-ui';
import { getRole, addRole, updateRole } from '@/api/role';

@Component({
  name: 'RoleForm',
})

export default class extends Vue {
  @Prop({ default: '' }) private roleCode!:string;

  private loading = false;

  private formData = {
    roleName: '',
    description: '',
  };

  private rules = {
    roleName: [
      { required: true, message: '권한명을 입력하세요.', trigger: 'blur' },
    ],
  }

  mounted() {
    if (this.roleCode) this.getRole();
  }

  private async addRole() {
    (this.$refs.roleForm as Form).validate((valid) => {
      if (valid) {
        this.loading = true;
        addRole(this.formData).then(() => {
          this.$notify({
            title: '메세지',
            message: '권한이 추가되었습니다.',
            type: 'success',
            duration: 2000,
          });
          this.$emit('getRoleList');
          this.cancel();
        });
        this.loading = false;
      }
    });
  }

  private async updateRole() {
    (this.$refs.roleForm as Form).validate((valid) => {
      if (valid) {
        this.loading = true;
        updateRole(this.roleCode, this.formData).then(() => {
          this.$notify({
            title: '메세지',
            message: '권한이 수정되었습니다.',
            type: 'success',
            duration: 2000,
          });
          this.$emit('getRoleList');
          this.cancel();
        });
        this.loading = false;
      }
    });
  }

  private getRole() {
    this.loading = true;
    getRole(this.roleCode).then((res) => {
      this.formData = { ...res.data };
      this.loading = false;
    });
  }

  private cancel() {
    this.$emit('close');
  }
}
