import request from '@/util/request';

const PATH = '/role';

export const getRoleList = (listQuery: any) => request({
  url: `${PATH}`,
  method: 'get',
});

export const getRole = (roleCode: string) => request({
  url: `${PATH}/${roleCode}`,
  method: 'get',
});

export const addRole = (data: any) => request({
  url: `${PATH}`,
  method: 'post',
  data,
});

export const updateRole = (roleCode: string, data: any) => request({
  url: `${PATH}/${roleCode}`,
  method: 'put',
  data,
});

export const deleteRole = (roleCode: string) => request({
  url: `${PATH}/${roleCode}`,
  method: 'delete',
});
