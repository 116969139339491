
















































import {
  Component,
  Vue,
  Prop,
} from 'vue-property-decorator';
import {
  addUser,
  getUser,
  uniqueId,
  updateUser,
} from '@/api/user';
import { getRoleList } from '@/api/role';
import { ElForm } from 'element-ui/types/form';
import { ElInput } from 'element-ui/types/input';

@Component({
  name: 'UserForm',
})
export default class extends Vue {
  @Prop({ default: '' }) private uid!: string;

  mounted() {
    this.setForm();
  }

  private formData = {
    userId: '',
    userPassword: '',
    userPasswordCheck: '',
    actualName: '',
    roles: [],
  };

  private userIdValidator = (rule: any, value: string, callback: Function) => {
    if (!this.uid) {
      uniqueId(value).then((res) => {
        if (res.data) callback(new Error('중복된 아이디입니다.'));
      });
    }
    callback();
  };

  private passwordValidator = (rule: any, value: string, callback: Function) => {
    if (!value) callback(new Error('패스워드를 입력해주세요.'));
    if (value.length < 8 || value.length > 20) callback(new Error('8~20자로 입력해주세요.'));
    callback();
  };

  private passwordCheckValidator = (rule: any, value: string, callback: Function) => {
    if ((this.$refs.userPassword as ElInput).value !== value) callback(new Error('패스워드와 일치하지 않습니다.'));
    callback();
  };

  private roleList = [];

  private loading = true;

  private passwordVisible = true;

  private rules = {
    userId: [
      { required: true, message: '아이디를 입력해주세요.', trigger: 'blur' },
      { pattern: /^[a-z]+[a-z0-9]{3,11}$/g, message: '아이디는 영문, 숫자, 4~12자로 구성되어야 합니다.', trigger: 'blur' },
      { validator: this.userIdValidator, trigger: 'blur' },
    ],
    userPassword: [
      { required: true, validator: this.passwordValidator, trigger: 'blur' },
    ],
    userPasswordCheck: [
      { required: true, validator: this.passwordCheckValidator, trigger: 'blur' },
    ],
    actualName: [
      { required: true, message: '이름을 입력해주세요.', trigger: 'blur' },
    ],
    roles: [
      {
        required: true,
        type: 'array',
        message: '권한을 선택해주세요.',
        trigger: 'blur',
      },
    ],
  }

  private setForm() {
    getRoleList({ page: 0, size: 100 }).then((res) => {
      this.roleList = res.data.content;
      this.loading = false;
    });
    if (this.uid) {
      this.passwordVisible = false;
      this.loading = true;
      getUser(this.uid).then((res) => {
        this.loading = false;
        this.formData = {
          ...this.formData,
          ...res.data,
        };
      });
    }
  }

  private handleClose() {
    this.$emit('close');
  }

  private handleSave() {
    (this.$refs.userForm as ElForm).validate((valid: boolean) => {
      if (valid) {
        if (this.uid) {
          updateUser(this.uid, this.formData).then(() => {
            this.$message.success('성공적으로 사용자를 수정했습니다.');
            this.$emit('save');
          });
        } else {
          addUser(this.formData).then(() => {
            this.$message.success('성공적으로 사용자를 추가했습니다.');
            this.$emit('save');
          });
        }
      }
    });
  }

  private handleVisiblePassword() {
    this.passwordVisible = !this.passwordVisible;
  }
}
