




















































































import { deleteUser, getUserList } from '@/api/user';
import { Component, Vue, Prop } from 'vue-property-decorator';
import Pagination from '@/components/Pagination/index.vue';
import UserForm from './UserForm.vue';

@Component({
  name: 'UserList',
  components: {
    Pagination,
    UserForm,
  },
})
export default class extends Vue {
  @Prop({ default: false }) private isRolePage!: boolean;

  mounted() {
    this.getUserList();
  }

  private searchTypeList = [
    { label: '아이디', value: 'userId' },
    { label: '이름', value: 'actualName' },
  ];

  private listQuery = {
    page: 1,
    size: 10,
    searchType: 'userId',
    searchValue: '',
    roleCode: '',
  };

  private uid = '';

  private totalElements = 0;

  private userList = [];

  private loading = true;

  private formVisible = false;

  private getUserList() {
    this.loading = true;
    getUserList(this.listQuery).then((res) => {
      if (this.listQuery.page > 1 && res.data.content.length < 1) {
        this.listQuery.page -= 1;
        this.getUserList();
      }
      this.loading = false;
      this.userList = res.data.content;
      this.totalElements = res.data.totalElements;
    }).catch(() => {
      this.$message.error('사용자들을 불러오는데 실패했습니다.');
    });
  }

  private handleSearch() {
    this.listQuery.page = 1;
    this.getUserList();
  }

  private handleVisibleForm(uid: string) {
    this.formVisible = !this.formVisible;
    this.uid = uid;
  }

  private handleDelete(uid: string) {
    this.$confirm('정말 사용자를 삭제하시겠습니까?', 'Warning', {
      confirmButtonText: '예',
      cancelButtonText: '아니오',
      type: 'warning',
    }).then(() => {
      deleteUser(uid).then(() => {
        this.$message.success('성공적으로 사용자를 삭제했습니다.');
        this.getUserList();
      }).catch(() => {
        this.$message.error('사용자를 삭제하는데 실패했습니다.');
      });
    });
  }

  private handleChangePage(pageable: any) {
    this.listQuery.page = pageable.page;
    this.getUserList();
  }

  private handleSuccessSave() {
    this.handleVisibleForm('');
    this.getUserList();
  }

  private handleChangeRoleCode(roleCode: string) {
    this.listQuery.roleCode = roleCode;
    this.getUserList();
  }
}
